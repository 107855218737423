<template>
  <AccountSharedLayout class="AccountLogoutView">
    <span slot="title">Logout</span>

    <template slot="main">
      <p v-if="processing">
        Logging out...
      </p>
      <p v-else>
        You are successfully logged out
      </p>
      <div class="links">
        <!-- <span v-t="'alreadyMember'"></span> -->
        <router-link
          v-t="'alreadyMemberLogin'"
          :to="{ name: 'AccountLoginView', query: { next: $route.query.next } }"
          tabindex="-1" />
      </div>
    </template>
  </AccountSharedLayout>
</template>

<translations>
  alreadyMember: 'Already a member?'
  alreadyMember_no: 'Allerede medlem?'
  alreadyMemberLogin: 'Login'
  alreadyMemberLogin_no: 'Logg inn'
</translations>

<script>
import AccountSharedLayout from '../components/AccountSharedLayout';

export default {
    components: { AccountSharedLayout },
    data() {
        return {
            processing: true
        };
    },
    async mounted() {
        if (this.$store.state.moduleAuth.isAuthenticated) {
            await this.$store.dispatch('moduleAuth/logout');
        }
        this.processing = false;
    }
};
</script>

<style lang="scss" scoped>
.AccountLogoutView {
    // padding: 1em;
    // background-color: white;
}

.links {
    a {
        text-decoration: none;
        color: #5fcf80;
    }
}
</style>
